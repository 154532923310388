const state = {
  banner:
  {
    // bannerL: {
    //   background01: '/img/banners/cloud.jpg',
    // },
    bannerM: {
      background01: '/img/banners/cloud.jpg',
    },
    threeImg: {
      imgL: '/img/banners/cat01.jpg',
      imgM: '/img/banners/cat03.jpg',
      imgR: '/img/banners/cat02.jpg',
    }
  },
  home:
  {
    homeImg: '/img/banners/cloud.jpg'
  },
  about:
  {
    title: 'About',
    img: '/img/logo.jpg',
    header: '關於享窩窩貓旅',
    text_title: '星空館',
    text: '享窩窩貓旅，位於新北市板橋區，靠近捷運新埔民生站。專門提供給可愛的貓主子不被外界打擾及安靜的貓咪旅館，距離店門口走出去不到一分鐘就有公車站牌：聯邦新城站，方便貓奴們接送及搭車哦。大大的活動空間/大大的木屋貓房，讓貓主子可以寬敞的住開心的玩。\n誠心邀請您帶家裡的寶貝貓主子前來住宿渡假喔！'
  },
  awards:
  {
    title: 'Awards',
    image: ['/img/award01.jpg', '/img/award02.jpg'],
    text: '🔥特定寵物業 - 優等獎🔥\n🏠新北市特定寵物長照之家🏠\n活力充沛的小貓🐈\n活潑可愛的成貓🐈\n需要特別照顧的年長貓🐈\n我們秉持著親民的價格\n五星級的照顧\n給喵喵一個最舒適的住宿環境😻',
    pn: '合法寵物旅館字號A1070628'
  },
  price:
  {
    title: '房型介紹',
    rooms: [
      {
        room: '星空房',
        fee: '280',
        img: '/img/roomA01.jpg',
        cats: '最多住2隻喵',
        info: '長80 x 寬80 x 高120 (cm)',
        detail: '獨立小木屋\n非鐵籠\n獨立空調'
      },
      {
        room: '溫馨房',
        fee: '380',
        img: '/img/roomB01.jpg',
        cats: '最多住3隻喵',
        info: '長90 x 寬90 x 高220 (cm)',
        detail: '獨立小木屋\n非鐵籠\n獨立空調\n24hr監控錄影 (選配)'
      },
      {
        room: '家庭房',
        fee: '550',
        cats: '最多住5隻喵',
        img: '/img/roomC01.jpg',
        info: '長120 x 寬120 x 高240 (cm)',
        detail: '獨立小木屋\n非鐵籠\n獨立空調\n流動式飲水機\n24hr監控 (選配)'
      }
    ]
    ,
    text: null
  },
  facilities:
  {
    title: 'facilities',
    header: '住宿環境 & 設備介紹',
    text: [
      {
        title: 'PUBLIC',
        subtitle: '公共空間',
        description: '寬敞獨立放風空間及時間',
        image: '/img/facilities/public.jpg'
      },
      {
        title: 'MONITOR',
        subtitle: '監視設備',
        description: '24hr監控錄影, 紅外線夜視功能',
        image: '/img/facilities/monitor.jpg'
      },
      {
        title: 'SECURE',
        subtitle: '安全住宿',
        description: '出入兩道門禁',
        image: '/img/facilities/doors.jpg'
      },
      {
        title: 'LITTER',
        subtitle: '貓砂品質',
        description: '提供知名品牌貓砂及木屑沙',
        image: '/img/facilities/litter.jpg'
      },
      {
        title: 'AC',
        subtitle: '全天候空調',
        description: '24hr冷暖空調',
        image: '/img/facilities/AC.jpg'
      },
      {
        title: 'AUTOMATIC WATER DISPENSER',
        subtitle: '自動飲水器',
        description: '滿足需要流動飲用水的貓咪',
        image: '/img/facilities/water_dispenser.jpg'
      },
      {
        title: 'PLAY',
        subtitle: '遊憩時間',
        description: '獨立放風散步遊戲時間',
        image: '/img/facilities/play.jpg'
      },
      {
        title: 'UV STERLILIZATION LAMP',
        subtitle: '紫外線滅菌燈',
        description: '紫外線殺菌燈消毒貓房',
        image: '/img/facilities/UV_light.jpg'
      },
    ]
  },
  important:
  {
    title: 'important',
    header: 'IMPORTANT',
    h_subtitle: '住宿須知',
    image: '/img/important01.jpg',
    text: [
      {
        subtitle: '一般須知',
        detail: '*短期住宿 (以日計費，平日/節日)。\n*中/長期住宿 (可另洽)。\n*須預付總金額的30%做為訂金(僅適用非農曆春節假期)。\n*入住日前5天內取消訂房，恕不退還訂金，也無保留訂金。\n*入住當天須付清所有住宿費用 (提早退房無法退費, 請務必確認好日期) 。\n*費用包含純水、離氨酸、貓草、貓餅乾無限供應、貓沙(礦沙或木屑沙二選一)。'
      },
      {
        subtitle: '進房日',
        detail: '當天即算1天費用。'
      },
      {
        subtitle: '退房日',
        detail: '*8:30 AM 前接回貓咪，當日不計費。\n*11: 30 AM 前接回，收取0.5日費用。\n*5: 00 PM ~ 6: 00 PM 及 7: 40 PM ~ 9: 30 PM 前接回，收取1日費用。\n*9: 30 PM ~ 11: 30 PM 及其它非退房時段須求，會另收取150元費用。'
      },
      {
        subtitle: '*為維護主子們的住宿品質，本店採預約參觀及入住退房*',
        visitTime: '預約參觀時段',
        visitTimeDetail: '10:00 AM ~ 11:30 AM \n5:40 PM ~ 6:30 PM  \n7:40 PM ~ 9:00 PM',
        checkInOut: '進房/退房時段',
        checkInOutDetail: '8:20 AM ~ 11:30 AM\n5:00 PM ~ 6:30 PM \n7:40 PM ~ 9:20 PM',
        nonPublic: '不對外開放時段',
        nonPublicDetail: '11:30AM ~ 5:00PM (午睡&打掃)\n6:00 PM ~ 7:40 PM  (餵食&打掃) '
      },
      {
        subtitle: '為維護貓咪住宿品質及健康，恕不接受以下狀況之貓咪入住',
        detail: '*剛結紮。\n* 未除蚤完成。\n*患有貓白血病、貓愛滋、貓瘟、其他重大疾病。\n* 患有具傳染性之疾病。\n* 如發現貓咪有感冒症狀 / 身體不適 / 身上或排泄物中有蟲體，將徵詢家長意見代送合格獸醫院診所。\n(如診斷結果為傳染性疾病將委由獸醫院進行照料，期間所產生之費用將由飼主自行給付)\n*若隱瞞貓咪疾病，並影響其他貓咪權益引發之任何傷亡，本店恕不負責。\n(如先天性疾病、慢性疾病、未注射預防疫苗、隱藏性疾病等任何狀況未告知)'
      },
      {
        subtitle: '以下狀況之貓咪入住，請洽業者討論',
        detail: '* 重大手術七天內，及未拆線的貓咪。\n* 正在發情期中。\n* 2年內未施打預防針，或在72小時內剛施打預防針之貓貓。\n* 年齡未滿4個月，或大於12歲之貓咪。'
      },
      {
        subtitle: '其它住意事項',
        detail: '* 享窩窩(含星空館) 保留接受與拒絕寵物貓之住宿權利。\n* 寄宿貓之主人於寄宿期滿日，除經享窩窩(含星空館) 同意繼續留宿外，應即日將寵物領回，不得有誤；逾期48小時滯留者，應視作寄宿貓主惡意棄養，享窩窩(含星空館) 得聯絡警方並依動物保護法處置，沒收寄宿費，寄宿貓之主人不得主張任何權利。\n* 入住前，請貓主務必先幫貓貓們修剪指甲及點防蚤藥劑(例如:蚤不到、寵愛….等)'
      }
    ]
  },
  album:
  {
    title: 'album',
    header: 'ALBUM',
    image: [
      '/img/gallery/pix01.jpg',
      '/img/gallery/pix02.jpg',
      '/img/gallery/pix03.jpg',
      '/img/gallery/pix04.jpg',
      '/img/gallery/pix05.jpg',
      '/img/gallery/pix06.jpg',
      '/img/gallery/pix07.jpg',
      // '/img/gallery/pix08.jpg',
      '/img/gallery/pix09.jpg'
    ]
  },
  contact:
  {
    text: '❤還在考慮出遠門❤\n不知把最愛的喵喵送到哪住宿嗎？\n❤放心的交給專業的我們吧❤\n\n🐈專人貼心照顧🐈\n24小時監控及冷暖空調\n🐈寬敞獨立放風空間及時間🐈',
    fbUrl: 'https://www.facebook.com/nekohouse.cat',
    fb1: '享窩窩　|　總館',
    fbUrl2: 'https://www.facebook.com/starhousecat/',
    fb2: '享窩窩　|　本館　|　星空貓旅分館',
    mobile1: '聯絡電話 1　|　0963-477721',
    mobile2: '聯絡電話 2　|　0912-951112',
    lineID: 'LINE ID　|　christykao0721',
    address: '地址　|　新北市板橋區長江路一段136巷10號1F',
    opentime: '營業時間　|　8:30 AM - 11:30 PM　(預約制)',
    pn: '合法寵物旅館字號A1070628　|',
    copyright: '享窩窩貓咪旅館 starhousecat.com Copyright 2020, All Rights Reserved　|'
  }
}

export default state