import React from 'react'
import Rellax from 'rellax'
import ScrollOut from 'scroll-out'

import './Banner.css'

class BannerL extends React.Component {
  componentDidMount() {
    // Rellax
    this.rellax = new Rellax('.rellax.bannerImg', {
      center: true,
      speed: -0.8,
    })
  }
  render() {
    return (
      <div>
        <figure className="rellax banner">
          <img className="rellax bannerImg" src={this.props.imgSrc} alt="banner" />
        </figure>
      </div>
    )
  }
}

class BannerM extends React.Component {
  componentDidMount() {
    // Rellax
    this.rellax = new Rellax('.bannerM_background', {
      // center: true,
      speed: -1.5,
    })
  }
  render() {
    return (
      <div className="bannerM" style={{ backgroundColor: this.props.bkcolor }} >
        {this.props.gap}
        <div className="bannerM_ctn">
          <img className="bannerM_background" src={this.props.imgSrc} alt="cloud" />

        </div >
      </div >
    )
  }
}

class ThreeImg extends React.Component {
  componentDidMount() {
    ScrollOut({
      targets: '.threeImg_individual',
      once: true,
      threshold: 0.8,
      onShown: function (el) {
        // remove the class
        el.classList.remove('animate__flipInX', 'animate__animated')
        // force reflow
        void el.offsetWidth;
        // re-add the animated cl
        el.classList.add('animate__flipInX', 'animate__animated')
      }
    })
  }
  render() {
    return (
      <div className="threeImg" style={{ backgroundImage: this.props.bkcolor }}>
        {this.props.gap}
        <div className="threeImg-ctn">
          <img src={this.props.imgL} alt="cat-pix" className="threeImg_individual" />
          <img src={this.props.imgM} alt="cat-pix" className="threeImg_individual" />
          <img src={this.props.imgR} alt="cat-pix" className="threeImg_individual" />
        </div>
      </div>
    )
  }
}

// class BannerZoom extends React.Component {
//   componentDidMount() {
//     ScrollOut();
//   }
//   render() {
//     return (
//       <div>

//       </div>
//     )
//   }
// }

export { BannerL, BannerM, ThreeImg }