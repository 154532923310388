import React from 'react'
import { Element } from 'react-scroll'
import ScrollOut from 'scroll-out'
import "animate.css/animate.css"

import './Important.css'
import state from '../../store'
// import VisitTime from '../../SVG/VisitTime'
import StarHouse from '../StarHouse'

const { header, h_subtitle, text, image } = state.important

class Important extends React.Component {

  componentDidMount() {
    ScrollOut({
      targets: '.fLeft',
      threshold: 0.2,
      once: true,
      onShown(el) {
        el.classList.add('animate__fadeInLeft', 'animate__animated');
      }
    });
    ScrollOut({
      targets: '.fRight',
      threshold: 0.2,
      once: true,
      onShown(el) {
        el.classList.add('animate__fadeInRight', 'animate__animated');
      }
    });
  }

  importantContainer = () => {
    const content =
      text.map(({ subtitle, detail, visitTime, visitTimeDetail, checkInOut, checkInOutDetail, nonPublic, nonPublicDetail }, index) =>
        <div
          key={index}
          className={`txt_wrapper noto_font ${index % 2 === 0 ? 'fLeft' : 'fRight'}`}
        >
          <div className="im_subtitle">{subtitle}</div>
          {visitTime ?
            <div className="visitTimewrapper">
              {/*<VisitTime />*/}
              <div className="visitTimeInfo">
                <div className="im_subtitle">{visitTime}</div>
                <div>{visitTimeDetail}</div>
                <div className="im_subtitle">{checkInOut}</div>
                <div>{checkInOutDetail}</div>
                <div className="im_subtitle">{nonPublic}</div>
                <div>{nonPublicDetail}</div>
              </div>
            </div>
            : null
          }
          <div className="eachDetail">{detail}</div>
          <hr />
        </div>
      )
    return content
  }

  render() {
    return (
      <section id="important">
        {this.props.gap}
        <Element name="important" className="element">
          <div className="importantContent">
            <header>
              <div className="important_title">{header}</div>
              <div className="line_goThrough"></div>
            </header>
            <div className="catL_wrapper">
              <img className="rotateY blueCat catL" src={image} alt="cat" />
            </div>
            <StarHouse className="neko01" />
            <div className="blockYellow yellow01"></div>
            <div className="blockBlue blue01"></div>
            <div className="blockYellow yellow02"></div>
            <div className="blockBlue blue02"></div>
            <div className="blockYellow yellow03"></div>
            <div className="blockBlue blue03"></div>
            <StarHouse className="neko02" />
            <StarHouse className="neko03" />
            <StarHouse className="neko04" />
            <StarHouse className="neko05" />
            <div className="important_wrapper noto">
              <p className="h_subtitle">{h_subtitle}</p>
              {this.importantContainer()}
            </div>
          </div>
        </Element>
      </section>
    )
  }
}

export default Important