import React from 'react'
import { Element } from 'react-scroll'

import { Paw } from '../Icon'
import './Facilities.css'
import state from '../../store'
import InfoBlock from '../InfoBlock'

const { header } = state.facilities



const Facilities = (props) => {
  return (
    <section id="facilities">
      <Element name="facilities" className="element">
        {props.gap}
        <div className="facilities-ctn">
          <header className="facilities-title">
            <Paw paw="facilitiesPaw" />
            <div className="title abril-fatface noto">{header}</div>
          </header>
          <InfoBlock />
        </div>
      </Element>
    </section>
  )
}

export default Facilities