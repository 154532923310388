import React from 'react'
import { Element } from 'react-scroll'
import ScrollOut from 'scroll-out'

import state from '../../store'
import './Awards.css'

const { text, image, pn } = state.awards

class Awards extends React.Component {
  componentDidMount() {
    ScrollOut({
      targets: '.awardBlockL,.awardBlockL2',
      onShown: function (el) {
        // remove the class
        el.classList.remove('animate__slideOutLeft', 'animate__animated', 'animate__faster', '500ms')
        // force reflow
        void el.offsetWidth;
        // re-add the animated cl
        el.classList.add('animate__slideOutLeft', 'animate__animated', 'animate__faster', '500ms')
      }
    })
    ScrollOut({
      targets: '.awardBlockR,.awardBlockR2',
      onShown: function (el) {
        // remove the class
        el.classList.remove('animate__slideOutRight', 'animate__animated', 'animate__faster', '500ms')
        // force reflow
        void el.offsetWidth;
        // re-add the animated cl
        el.classList.add('animate__slideOutRight', 'animate__animated', 'animate__faster', '500ms')
      }
    })
    ScrollOut({
      targets: '.awText',
      once: true,
      threshold: 0.5,
      onShown: function (el) {
        // remove the class
        el.classList.remove('animate__fadeIn', 'animate__animated')
        // force reflow
        void el.offsetWidth;
        // re-add the animated cl
        el.classList.add('animate__fadeIn', 'animate__animated')
      }
    })
  }
  render() {
    return (
      <section id="awards">
        <Element name="awards" className="element">
          {this.props.gap}
          <div className="awardsBackground">
            <div className="awardsContent">
              <figure className="awardImg-ctn">
                <div className="awardBlockL"></div>
                <div className="awardBlockL2"></div>
                <img
                  className="awardsImg01"
                  src={image[0]} alt="awards01"
                />
              </figure>
              <div className="awTextBox">
                <div
                  className="awText noto"
                >
                  {text}
                </div>
                <div
                  className="awText noto pn"
                >
                  {pn}
                </div>
              </div>
              <figure className="awardImg-ctn">
                <div className="awardBlockR"></div>
                <div className="awardBlockR2"></div>
                <img
                  className="awardsImg02"
                  src={image[1]}
                  alt="awards02"
                />
              </figure>
            </div >
          </div>
        </Element>
      </section >
    )
  }
}

export default Awards