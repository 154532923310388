import React from 'react'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import 'swiper/js/swiper.min.js'
import './GalleryLoop.css'
import state from '../store'


class GalleryLoop extends React.Component {
  componentDidMount() {
    var galleryThumbs = new Swiper('.gallery-thumbs', {
      spaceBetween: 10,
      slidesPerView: 4,
      loop: true,
      freeMode: true,
      loopedSlides: 5, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
    var galleryTop = new Swiper('.gallery-top', {
      spaceBetween: 10,
      loop: true,
      loopedSlides: 5, //looped slides should be the same
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    });
  }
  render() {
    const { image } = state.album

    return (
      <div className="galleryWrapper">
        <div className="swiper-container gallery-top gallery">
          <div className="swiper-wrapper">
            {/* map all pix from gallery file */}
            {
              image.map((img, index) =>
                <div
                  className="swiper-slide gallery"
                  style={{ backgroundImage: `url(${img})` }}
                  piximg={img}
                  key={index}>
                </div>
              )
            }
          </div >

          <div className="swiper-button-next swiper-button-white"></div>
          <div className="swiper-button-prev swiper-button-white"></div>
        </div >
        <div className="swiper-container gallery-thumbs">
          <div className="swiper-wrapper">
            {/* map all pix from gallery file */}
            {
              image.map((img, index) =>
                <div
                  className="swiper-slide gallery"
                  style={{ backgroundImage: `url(${img})` }}
                  piximg={img}
                  key={index}>
                </div>
              )
            }
          </div >
        </div >
      </div >
    )
  }
}

export default GalleryLoop