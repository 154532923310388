import React from 'react'
import './StarHouse.css'

// const name = 'nekoTxt'

class StarHouse extends React.Component {

  render() {
    let className = ''
    className += ' ' + this.props.className
    return (
      <div className={className}>
        <div className="nekoTxt abril-fatface">STARHOUSE</div>
        <div className="nekoTxt abril-fatface">STARHOUSE</div>
        <div className="nekoTxt abril-fatface">STARHOUSE</div>
      </div>
    )
  }
}

export default StarHouse