import React from 'react'
import ScrollOut from 'scroll-out'

import './Gap.css'

class Gap extends React.Component {
  componentDidMount() {
    ScrollOut({
      targets: '.ani_gap',
      once: true
    })
  }

  render() {
    return (
      <div className="ani_gap"></div>
    )
  }
}

export default Gap