import React from 'react'
import { Element } from 'react-scroll'
import ScrollOut from 'scroll-out'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import 'animate.css/animate.css'
import state from '../../store'
import './Home.css'

const { homeImg } = state.home

class Home extends React.Component {
  componentDidMount() {
    ScrollOut(
      {
        targets: '.home-neko',
        onShown: function (el) {
          // remove the class
          el.classList.remove('animate__flipInX', 'animate__animated')
          // force reflow
          void el.offsetWidth;
          // re-add the animated cl
          el.classList.add('animate__flipInX', 'animate__animated')
        }
      }
    )
    ScrollOut(
      {
        targets: '.home-house',
        threshold: 0.2,
        onShown: function (el) {
          // remove the class
          el.classList.remove('animate__flipInX', 'animate__animated')
          // force reflow
          void el.offsetWidth;
          // re-add the animated cl
          el.classList.add('animate__flipInX', 'animate__animated')
        }
      }
    )
    ScrollOut(
      {
        targets: '.home-starsky',
        threshold: 0.2,
        onShown: function (el) {
          // remove the class
          el.classList.remove('animate__flipInX', 'animate__animated')
          // force reflow
          void el.offsetWidth;
          // re-add the animated cl
          el.classList.add('animate__flipInX', 'animate__animated')
        }
      }
    )
    ScrollOut(
      {
        targets: '.inverted-triangle',
        threshold: 0.2,
        onShown: function (el) {
          // remove the class
          el.classList.remove('animate__fadeInDown', 'animate__animated')
          // force reflow
          void el.offsetWidth;
          // re-add the animated cl
          el.classList.add('animate__fadeInDown', 'animate__animated')
        }
      }
    )
  }

  render() {
    return (
      <section id="home">
        <Element name="home" className="element home-ctn">
          <div
            className="homeImg"
            style={{ backgroundImage: `url(${homeImg})` }}
          >
            <div className="neko-house">
              <p className="home-neko noto">享窩窩</p>
              <p className="home-house noto">貓旅館</p>
              <p className="home-starsky noto text_center">- 星空館 -</p>
              <div className="inverted-triangle">
                <FontAwesomeIcon icon={faCaretDown} className="fa-caret-down" />
                <FontAwesomeIcon icon={faCaretDown} className="fa-caret-down" />
                <FontAwesomeIcon icon={faCaretDown} className="fa-caret-down" />
              </div>
            </div>
          </div>
          {/* <div
            className="galaxy"
            style={{ backgroundImage: `url(${img})` }}
          >
          </div> */}

        </Element>
      </section>
    )
  }
}

export default Home