import React from 'react'
import { Element } from 'react-scroll'
import ScrollOut from 'scroll-out'
import 'animate.css/animate.css'
import { Paw } from '../Icon'

import state from '../../store'
import './About.css'

const { header, text, text_title } = state.about

class About extends React.Component {

  componentDidMount() {

    // ScrollOut
    ScrollOut({
      targets: '.about-header',
      once: true,
      onShown: function (el) {
        // remove the class
        el.classList.remove('animate__fadeInUp', 'animate__animated', 'animate__delay-1s')
        // force reflow
        void el.offsetWidth;
        // re-add the animated cl
        el.classList.add('animate__fadeInUp', 'animate__animated', 'animate__delay-1s')
      }
    })
    ScrollOut({
      targets: '.about-line',
      once: true,
      onShown: function (el) {
        // remove the class
        el.classList.remove('animate__fadeInRight', 'animate__animated')
        // force reflow
        void el.offsetWidth;
        // re-add the animated cl
        el.classList.add('animate__fadeInRight', 'animate__animated')
      }
    })
    ScrollOut({
      targets: '.about-text-box',
      once: true,
      onShown: function (el) {
        // remove the class
        el.classList.remove('animate__fadeIn', 'animate__animated')
        // force reflow
        void el.offsetWidth;
        // re-add the animated cl
        el.classList.add('animate__fadeIn', 'animate__animated')
      }
    })
  }


  render() {
    return (
      <div>
        <section id="about">
          <Element name="about" className="element">
            <div className="about-ctn">
              <div className="about-text-ctn">
                <div className="about-title-ctn">
                  <div className="about-header noto">
                    {header}</div>
                </div>
                <div className="about-line"></div>
                <div className="about-text-box">
                  <div className="textbox-left">
                    <img className="rellax logoImg" src="/img/logo.jpg" alt="neko_house logo" />
                  </div>
                  <div className="textbox-right">
                    <div className="noto abt-txt-title"><Paw paw="aboutPaw" />{text_title}<Paw paw="aboutPaw" /></div>
                    <div className="noto about-text">{text}</div>
                  </div>
                </div>
                {/* <div className="about-line"></div> */}
              </div>
            </div>
          </Element>
        </section>
      </div>
    )
  }
}

export default About