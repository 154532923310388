import React from 'react'
import { Element } from 'react-scroll'

import { FB } from '../Icon'
import state from '../../store'
import './Contact.css'

const { fbUrl, fbUrl2, mobile1, mobile2, lineID, address, opentime, pn, copyright, fb1, fb2 } = state.contact

const Contact = () => {
  return (
    <div>
      <section id="contact">
        <Element name="contact" className="element">
          <div className="contact-ctn">
            <div className="contact-info noto">
              <div className="contactL">
                <p>{address}</p>
                <p>{mobile1}</p>
                <p>{mobile2}</p>
                <p>{lineID}</p>
                <p>{opentime}</p>
              </div>
              <div className="contactR">
                <div className="fb">
                  <a
                    href={fbUrl} target="_blank"
                    rel="noopener noreferrer" className="fbIcon"
                  >
                    <FB sizeValue="2x" />
                  </a>
                  <p>{fb1}</p>
                </div>
                <div className="fb">
                  <a
                    href={fbUrl2}
                    target="_blank" rel="noopener noreferrer"

                    className="fbIcon"
                  >
                    <FB sizeValue="2x" />
                  </a>
                  <p>{fb2}</p>
                </div>
              </div>
            </div>
          </div>
        </Element>
      </section>
      <footer className="noto">
        <p>{pn}</p>
        <p>{copyright}</p>
      </footer>
    </div>
  )
}

export default Contact