import React from 'react'

import Contact from './Components/Sections/Contact'
import Gap from './Components/Gap'
import Home from './Components/Sections/Home'
import About from './Components/Sections/About'
import Awards from './Components/Sections/Awards'
import Price from './Components/Sections/Price'
import Facilities from './Components/Sections/Facilities'
import Important from './Components/Sections/Important'
import Album from './Components/Sections/Album'
import Navbar from './Components/Navbar'
import { BannerM, ThreeImg } from './Components/Banner'
import state from './store'

const { bannerM, threeImg } = state.banner

class App extends React.Component {

  render() {
    return (
      <div>
        <Navbar />
        <Home />
        <BannerM
          imgSrc={bannerM.background01}
          bkcolor="rgba(243,217,218,1)"
          gap={<Gap />}
        />
        <ThreeImg
          imgL={threeImg.imgL}
          imgM={threeImg.imgM}
          imgR={threeImg.imgR}
          bkcolor="linear-gradient(0deg, rgba(218,221,237,1) 17%, rgba(243,217,218,1) 100%)"
          gap={<Gap />}
        />
        <About gap={<Gap />} />
        <Awards gap={<Gap />} />
        <Price gap={<Gap />} />
        <Facilities gap={<Gap />} />
        <Important gap={<Gap />} />
        <Album gap={<Gap />} />
        <Contact />
      </div >
    )
  }
}

export default App