import React from 'react'
import { Element } from 'react-scroll'

import GalleryLoop from '../GalleryLoop'
import state from '../../store'
import './Album.css'

const { header } = state.album

const Album = (props) => {
  return (
    <section id="album">
      {props.gap}
      <Element name="album" className="element">
        <div className="album-ctn">
          <header>
            <div className="album_title abril-fatface">{header}</div>
          </header>
          <GalleryLoop />
        </div>
      </Element>
      {/* gap effect for the up comming contact section */}
      {props.gap}
    </section>
  )
}

export default Album