import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import ScrollOut from 'scroll-out'
// import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Link } from 'react-scroll'

import { Paw } from './Icon'
import './Navbar.css'

export default class Navbar extends React.Component {
  constructor() {
    super()
    this.state = {
      active: false
    }
  }

  componentDidMount() {
    ScrollOut({
      targets: '.hambuger-ctn',
      offset: 400
    })
  }

  navOpen = () => {
    this.setState(prevState => ({ active: !prevState.active }))
  }

  render() {
    const { active } = this.state
    return (
      < nav >
        <div className="hambuger-ctn">
          <FontAwesomeIcon icon={faBars} size="3x" className="navItem bars" onClick={this.navOpen} />
        </div>
        <ul className={`nav-links eng_font ${active ? 'open' : ''}`}>
          <div className="nav-ctn">
            <div className="navlinks-border">
              <li>
                <Link
                  onClick={this.navOpen}
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={2000}
                  className="navItem noto"
                >
                  <Paw paw="navPaw" />
              關於享窩窩貓旅
            </Link>
              </li>
              <li>
                <Link
                  onClick={this.navOpen}
                  activeClass="active"
                  to="awards"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={2000}
                  className="navItem noto"
                >
                  <Paw paw="navPaw" />
              享窩窩獎項
            </Link>
              </li>
              <li>
                <Link
                  onClick={this.navOpen}
                  activeClass="active"
                  to="price"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={2000}
                  className="navItem noto"
                >
                  <Paw paw="navPaw" />
              房型介紹
            </Link>
              </li>
              <li>
                <Link
                  onClick={this.navOpen}
                  activeClass="active"
                  to="facilities"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={2000}
                  className="navItem noto"
                >
                  <Paw paw="navPaw" />
              住宿環境 & 設備介紹
            </Link>
              </li>
              <li>
                <Link
                  onClick={this.navOpen}
                  activeClass="active"
                  to="important"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={2000}
                  className="navItem noto"
                >
                  <Paw paw="navPaw" />
              重要事項
            </Link>
              </li>
              <li>
                <Link
                  onClick={this.navOpen}
                  activeClass="active"
                  to="album"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={2000}
                  className="navItem noto"
                >
                  <Paw paw="navPaw" />
              貓貓相本
            </Link>
              </li>
              <li>
                <Link
                  onClick={this.navOpen}
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={2000}
                  className="navItem noto"
                >
                  <Paw paw="navPaw" />
              聯絡我們
            </Link>
              </li>
            </div>
            <div className="navBtnBlock"></div>
          </div>
        </ul>
      </nav >
    )
  }

}