import React from 'react'
import state from '../store'
import ScrollOut from 'scroll-out'
import "animate.css/animate.css"

import './InfoBlock.css'

const { text } = state.facilities

class InfoBlock extends React.Component {

  componentDidMount() {
    ScrollOut({
      targets: '.fLeft',
      once: true,
      threshold: 0.8,
      onShown(el) {
        el.classList.add('animate__fadeInLeft', 'animate__animated');
      }
    });
    ScrollOut({
      targets: '.fRight',
      once: true,
      threshold: 0.8,
      onShown(el) {
        el.classList.add('animate__fadeInRight', 'animate__animated');
      }
    });
  }

  blocks = () => {
    const infoBlocks = text.map(({ title, subtitle, description, image }, index) =>
      <div
        key={title}
        className={`infoBlockContainer ${index % 2 === 0 ? 'fLeft' : 'fRight'}`}
      >
        <img className="infoImg" src={image} alt={title} />
        <div className="info-ctn">
          <div className="block_info">
            <div className="text_center infotitle noto">{subtitle}</div>
            <div className="text_center blockText noto">{description}</div>
          </div>
        </div>
      </div>
    )
    return infoBlocks
  }

  render() {
    return (
      <div className="block_container">
        <div className="block_center">
          {this.blocks()}
        </div>
      </div>
    )
  }

}

export default InfoBlock


