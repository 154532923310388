import React from 'react'
import { Element } from 'react-scroll'
import ScrollOut from 'scroll-out'

import './Price.css'
import state from '../../store'

const { rooms } = state.price

class Price extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      room: 0,
    }
  }

  componentDidMount() {
    ScrollOut({
      targets: '.price-ctn',
      once: true,
      onShown: function (el) {
        // remove the class
        el.classList.remove('animate__fadeIn', 'animate__animated')
        // force reflow
        void el.offsetWidth;
        // re-add the animated cl
        el.classList.add('animate__fadeIn', 'animate__animated')
      }
    })
  }

  roomBtn = () => {
    return (
      <div className="price-button">
        {/* mapping room list */}
        {rooms.map(({ room }, index) =>
          <div
            className={this.changeColor(index)}
            // roomindex={index}
            onClick={() => this.clickRoom({ index })}
            key={room}
          >
            {room}
          </div>
        )}
      </div>
    )
  }

  changeColor = (index) => {
    if (index === this.state.room) {
      return 'button noto clicked text_center'
    } else {
      return 'button noto text_center'
    }
  }

  clickRoom = (props) => {
    this.setState({ room: props.index })
  }

  render() {
    return (
      <section id="price">
        {this.props.gap}
        <Element name="price" className="element">
          <div className="price-ctn">
            {this.roomBtn()}
            <div className="bottom-ctn">
              <div className="price-left">
                <div className="price-title noto text_center">
                  {rooms[this.state.room].room}
                </div>
                <div className="price-line-ctn">
                  <div className="price-line-info roboto">Info</div>
                  <div className="horizontal-line2"></div>
                </div>
                <div className="room-detail-center">
                  <p className="room-price noto">{rooms[this.state.room].fee}<span className="perday">/1天/1喵</span></p>
                  <div className="price-line"></div>
                  <p className="room-info noto">{rooms[this.state.room].cats}</p>
                  <div className="price-line"></div>
                  <p className="room-info noto">{rooms[this.state.room].info}</p>
                  <div className="price-line"></div>
                  <p className="room-detail noto">{rooms[this.state.room].detail}</p>
                </div>
              </div>
              <div className="price-right">
                <div className="roomImg-ctn">
                  <img className="room-img" src={rooms[this.state.room].img} alt={rooms[this.state.room].room} />
                </div>
              </div>
            </div>
          </div>
        </Element>
      </section >
    )
  }
}

export default Price