import React from 'react'
import { faPaw } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

const Paw = (props) => {
  return (
    <FontAwesomeIcon icon={faPaw} className={props.paw} />
  )
}

const FB = (props) => {
  return (
    <FontAwesomeIcon icon={faFacebook} size={props.sizeValue} />
  )
}

export { Paw, FB }